import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      index:1,
      title: '灵犀蓝图专业教学资源库',
      content:{
        keywords:'灵犀蓝图,职业教育,数字化,信息化,在线教育,智慧教育,碎片化,慕课,微课,知识点,技能点,知识图谱,课程图谱,教学图谱',
        description:'灵犀蓝图是集合专业教学资源库、教学互动应用、学生学业评价、岗位能力评价、 职业倾向评价、人才数据分析、教务信息管理服务于一体的一站式系统平台'
      }
    },
    component: HomeView
   
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      index:2,
      title: '灵犀蓝图专业教学资源库',
      content:{
        keywords:'灵犀蓝图,职业教育,数字化,信息化,在线教育,智慧教育,碎片化,慕课,微课,知识点,技能点,知识图谱,课程图谱,教学图谱',
        description:'灵犀蓝图是集合专业教学资源库、教学互动应用、学生学业评价、岗位能力评价、 职业倾向评价、人才数据分析、教务信息管理服务于一体的一站式系统平台'
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
