<template>
  <div>
    <el-carousel
      indicator-position="none"
      ref="caseRef"
      :height="caseHeight"
      arrow="never"
      :autoplay="false"
    >
      <el-carousel-item v-for="item in listResult" :key="item.id">
        <div class="case_listResult">
          <div
            class="case_listResult_item"
            v-for="item in item.list"
            :key="item.id"
            @click="handleCLickLink(item.link)"
          >
            <el-image class="image" :src="item.imgSrc" fit="fil"></el-image>
            <div class="listResult_item_bottom">
              <div class="listResult_left">
                <div class="schoolName">{{ item.schoolName }}</div>
                <div class="title">{{ item.title }}</div>
              </div>
              <el-image
                class="imageRight"
                :src="item.rightIcon"
                fit="fil"
              ></el-image>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="InstitutionalCase_circle">
      <div
        v-for="(item, idx) in listResult.length"
        :key="item"
        :class="{ curLightHeight: lightHeightCase == idx }"
        @click="handleCLickCase(idx)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    caseHeight: {
      type: String,
      default: "",
    },
    listResult: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      lightHeightCase: 0,
    };
  },

  methods: {
    //案例 点击资源库跳转
    handleCLickLink(link) {
      window.open(link);
    },
    handleCLickCase(index) {
      this.lightHeightCase = index;
      this.$refs.caseRef.setActiveItem(index);
    },
  },
};
</script>


<style lang="scss" scoped>
.case_listResult {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  padding-bottom: 100px;
  .case_listResult_item {
    width: 32%;
    height: 363px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.17);
    margin-bottom: 46px;
    overflow: hidden;
    cursor: pointer;
    .image {
      width: 100%;
      height: 283px;
      transition: transform 0.3s ease;
    }
    .image:hover {
      transform: scale(1.1);
    }
    .listResult_item_bottom {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .listResult_left {
        line-height: 1.5;
        .schoolName {
          font-size: 14px;
          color: #333;
          font-weight: 600;
        }
        .title {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  // .case_listResult_item:hover {
  //   transform: scale(1.1);
  // }
}
.InstitutionalCase_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  & > div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #cbdcff;
    cursor: pointer;
    margin-left: 16px;
  }
  .curLightHeight {
    background: #2979ff;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .case_listResult {
    width: 100%;
    .case_listResult_item {
      height: 263px;
      .image {
        width: 100%;
        height: 180px;
      }
      .listResult_item_bottom {
        padding: 0px;
        .imageRight {
          display: none;
        }
      }
    }
  }
  .InstitutionalCase_circle {
    bottom: 214px;
    z-index: 100px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) and (max-width: 1336px) {
  .case_listResult {
    width: 100%;
    .case_listResult_item {
      width: 32%;
      height: 260px;
      .image {
        width: 100%;
        height: 183px;
      }
      .listResult_item_bottom {
        padding: 0 10px;
        .listResult_left {
          .schoolName,
          .title {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
