<template>
  <div class="home">
    <div class="home_top">
      <div class="home_banner">
        <img class="imgLogo" :src="logoSrc" alt="" />
      </div>
      <div class="home_jianjie">
        <div class="jianjie_left"></div>
        <div class="jianjie_center">
          <p class="p_title">{{ detialData.title }}</p>
          <p class="p_titleEn">{{ detialData.titleEn }}</p>
          <p class="p_name">{{ detialData.name }}</p>
          <p class="p_center">{{ detialData.center }}</p>
          <p class="p_dec">{{ detialData.dec }}</p>
        </div>
      </div>
      <div class="home_tab">
        <div :class="[
          'home_tab_item',
          tabActive == index ? 'tabActive' : ''
        ]" v-for="(item,index) in homeTabList" :key="item.id" @click="handleCLickTab(index)">
          <el-image class="image" :src="tabActive == index ? item.iconActive : item.icon"></el-image>
          <p class="name">{{ item.name }}</p>
          <p class="title">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- 设计思路 -->
    <div class="mentalityDesigning">
      <div class="com_heard">
        <div class="com_title">{{ mentalityDesigning.title }}</div>
        <div class="com_titleEn">{{ mentalityDesigning.titleEn }}</div>
      </div>
      <div class="com_center">{{ mentalityDesigning.center }}</div>
    </div>
    <!-- 平台架构 -->
    <div class="platformArchitecture">
      <div class="com_heard">
        <div class="com_title">{{ platformArchitecture.title }}</div>
        <div class="com_titleEn">{{ platformArchitecture.titleEn }}</div>
      </div>
      <div class="com_center">{{ platformArchitecture.center }}</div>
    </div>
    <!-- 特色功能 -->
    <div class="specialFunction">
      <div class="com_heard">
        <div class="com_title">{{ specialFunction.title }}</div>
        <div class="com_titleEn">{{ specialFunction.titleEn }}</div>
      </div>
      <div class="specialFunction_center inner">
        <div class="specialFunction_center_left">
            <div
              :class="[
                'titleList',
                 lightHeight == idx ? 'curLightHeight' : ''
              ]"
              
              v-for="(item, idx) in specialFunction.titleList"
              @mouseenter="handleMouseenterSpecial(idx)"
              @mouseleave="handleMouseLeaveSpecial(idx)"
            >
             <el-image class="image" :src="lightHeight == idx ? item.icon : item.iconActive" fit="cover"></el-image>
              <div class="name"> {{ item.name }}</div>
            </div>
         </div>
        <div class="specialFunction_center_right">
          <el-carousel
            indicator-position="none"
            direction="vertical"
            ref="cardShow"
            :height="'520px'"
            arrow="never"
            :autoplay="true"
            @change="handleChangCarouse"
        >
          <el-carousel-item
            v-for="item in specialFunction.centerList"
            :key="item.id"
          >
            <div class="carouse_warp">
               <el-image
                  class="image"
                  :src="item.imgSrc"
                  fit="fill"
                ></el-image>
                <p class="carouse_dec">{{ item.dec }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        </div>
      </div>
      <div class="specialFunction_center_media inner">
        <div class="specialFunction_center_left">
            <div
              :class="[
                'titleList',
                 lightHeight == idx ? 'curLightHeight' : ''
              ]"
              
              v-for="(item, idx) in specialFunction.titleList"
              @mouseenter="handleMouseenterSpecial(idx)"
              @mouseleave="handleMouseLeaveSpecial(idx)"
            >
             <el-image class="image" :src="lightHeight == idx ? item.icon : item.iconActive" fit="fill"></el-image>
              <div class="name"> {{ item.name }}</div>
            </div>
         </div>
        <div class="specialFunction_center_right">
          <el-carousel
            indicator-position="none"
            direction="vertical"
            ref="cardShowMedia"
            :height="'460px'"
            arrow="never"
            :autoplay="true"
            @change="handleChangCarouse"
        >
          <el-carousel-item
            v-for="item in specialFunction.centerList"
            :key="item.id"
          >
            <div class="carouse_warp">
               <el-image
                  class="image"
                  :src="item.imgSrc"
                  fit="scale-down"
                ></el-image>
                <p class="carouse_dec">{{ item.dec }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        </div>
      </div>
    </div>
    <!-- 基础功能 -->
    <div class="basicFunction">
      <div class="basic_heard">
        <div class="basic_title">{{ basicFunction.title }}</div>
        <div class="basic_titleEn">{{ basicFunction.titleEn }}</div>
      </div>
      <div class="basicn_center inner">
        <el-carousel
          class="basicn_carousel"
          indicator-position="none"
          ref="basicRef"
          :height="'550px'"
          arrow="never"
          :autoplay="false"
          @change="handleChangBasicCarouse"
        >
          <el-carousel-item
            v-for="item in basicFunction.centerList"
            :key="item.id"
          >
            <div class="basicn_mod">
              <div class="basicn_left">
                <el-image
                  class="image"
                  :src="item.imgSrc"
                  fit="fill"
                ></el-image>
              </div>
              <div class="basicn_right">
                <div class="basicn_right_top">
                  <div class="right_title">{{ item.title }}</div>
                  <div class="right_name">{{ item.name }}</div>
                  <div class="right_dec" v-html="item.dec"></div>
                </div>
                <div class="basicn_right_bottom">
                  <el-divider class="right_bottom_divider"></el-divider>
                  <div class="featuresNane">{{ item.featuresNane }}</div>
                  <div class="features_warp">
                    <div
                      :class="[
                        item.features.length == 4
                          ? 'featuresItemFour'
                          : 'featuresItem',
                      ]"
                      v-for="featuresItem in item.features"
                      :key="featuresItem.id"
                    >
                      <el-image
                        class="image"
                        :src="featuresItem.featuresIcon"
                        fit="cover"
                      ></el-image>
                      <div class="featuresTitle">
                        {{ featuresItem.featuresTitle }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          class="basicn_carousel_media"
          indicator-position="none"
          ref="basicRefMedia"
          :height="'440px'"
          arrow="never"
          :autoplay="false"
          @change="handleChangBasicCarouse"
        >
          <el-carousel-item
            v-for="item in basicFunction.centerList"
            :key="item.id"
          >
            <div class="basicn_mod">
              <div class="basicn_left">
                <el-image
                  class="image"
                  :src="item.imgSrc"
                  fit="fill"
                ></el-image>
              </div>
              <div class="basicn_right">
                <div class="basicn_right_top">
                  <div class="right_title">{{ item.title }}</div>
                  <div class="right_name">{{ item.name }}</div>
                  <div class="right_dec" v-html="item.dec"></div>
                </div>
                <div class="basicn_right_bottom">
                  <el-divider class="right_bottom_divider"></el-divider>
                  <div class="featuresNane">{{ item.featuresNane }}</div>
                  <div class="features_warp">
                    <div
                      :class="[
                        item.features.length == 4
                          ? 'featuresItemFour'
                          : 'featuresItem',
                      ]"
                      v-for="featuresItem in item.features"
                      :key="featuresItem.id"
                    >
                      <el-image
                        class="image"
                        :src="featuresItem.featuresIcon"
                        fit="fill"
                      ></el-image>
                      <div class="featuresTitle">
                        {{ featuresItem.featuresTitle }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="basic_foot">
        <div class="basicFootWarp inner">
          <div
            v-for="(item, idx) in basicFunction.basicFoot"
            :key="item.id"
            :class="['basicFootItem', basicActive == idx ? 'basicActive' : '']"
            @mouseenter="handleMouseenter(idx)"
            @mouseleave="handleMouseLeave(idx)"
          >
            <div class="foot_title">{{ item.title }}</div>
            <div class="foot_titleEn">{{ item.titleEn }}</div>
            <el-divider
              v-if="basicActive !== idx"
              class="foot_divider"
            ></el-divider>
            <el-image
              v-if="basicActive !== idx"
              class="image"
              :src="item.icon"
              fit="cover"
            ></el-image>
            <div class="foot_dietal" v-if="basicActive == idx">
              <div>了解详情</div>
              <div>></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 院校案例 -->
    <div class="InstitutionalCase">
      <div class="com_heard">
        <div class="com_title">{{ InstitutionalCase.title }}</div>
        <div class="com_titleEn">{{ InstitutionalCase.titleEn }}</div>
      </div>
      <div class="case_center inner">
        <div class="case_warp">
          <div class="case_tap">
            <div
              v-for="(item, navIndex) in InstitutionalCase.navList"
              :key="item.id"
              :class="
                caseNavActive == navIndex
                  ? 'caseNavActive case_tab_item'
                  : 'case_tab_item'
              "
              @click="handleClickNav(navIndex)"
            >
              <el-image
                class="image"
                :src="caseNavActive == navIndex ? item.iconActive : item.icon"
                fit="fil"
              ></el-image>
              <div class="nav_title">{{ item.title }}</div>
            </div>
          </div>
          <div class="more">MORE+</div>
        </div>
        <div class="CaseCarousel">
          <CaseCarousel
            v-if="caseNavActive == 0"
            :listResult="InstitutionalCase.listResultAll"
            :caseHeight="'850px'"
          ></CaseCarousel>
          <CaseCarousel
            v-if="caseNavActive == 1"
            :listResult="InstitutionalCase.listResultOne"
            :caseHeight="'850px'"
          ></CaseCarousel>
          <CaseCarousel
            v-if="caseNavActive == 2"
            :listResult="InstitutionalCase.listResultTow"
            :caseHeight="'850px'"
          ></CaseCarousel>
        </div>
        <div class="CaseCarouselMedia">
          <CaseCarousel
            v-if="caseNavActive == 0"
            :listResult="InstitutionalCase.listResultAll"
            :caseHeight="'650px'"
          ></CaseCarousel>
          <CaseCarousel
            v-if="caseNavActive == 1"
            :listResult="InstitutionalCase.listResultOne"
            :caseHeight="'650px'"
          ></CaseCarousel>
          <CaseCarousel
            v-if="caseNavActive == 2"
            :listResult="InstitutionalCase.listResultTow"
            :caseHeight="'650px'"
          ></CaseCarousel>
        </div>
      </div>
    </div>
    <!-- 页面底部 -->
    <div class="paperFooter">
      <div class="comp_info inner">
        <a href="https://www.jwxx.com.cn" target="_blank"
          >技术支持：河南杰文信息技术有限公司</a
        >
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          豫ICP备12011404号-4
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CaseCarousel from "@/components/CaseCarousel.vue";
export default {
  name: "HomeView",
  components: {
    CaseCarousel,
  },
  data() {
    return {
      tabActive: 0,
      lightHeight: 0,
      basicActive: 0,
      caseNavActive: 0,

      logoSrc: require("@/assets/logoBg.png"),
      homeTabList: [
        {
          id: 0,
          icon: require("@/assets/one.png"),
          iconActive: require("@/assets/icon_jianrong_bai.png"),
          name: "兼容",
          title: "3D模型、虚拟仿真、多元资源兼容",
        },
        {
          id: 1,
          icon: require("@/assets/four.png"),
          iconActive: require("@/assets/changxin_icon_bai.png"),
          name: "创新",
          title: "新型数字教材、教学资源包 自主引用讨论",
        },
        {
          id: 2,
          icon: require("@/assets/tow.png"),
          iconActive: require("@/assets/ai_icon_bai.png"),
          name: "AI大模型",
          title: "GPT问答、数字人教师 AI大模型",
        },
        {
          id: 3,
          icon: require("@/assets/three.png"),
          iconActive: require("@/assets/zhineng_icon_bai.png"),
          name: "智能",
          title: "大模型、大数据 数据智能统计",
        },
      ],
      detialData: {
        title: "灵犀蓝图专业资源库",
        titleEn: "CONNECT BLUEPRINTS PROFESSIONAL RESOURCE LIBRARY ",
        name: "AI、大数据、大模型，助力教育数字化发展",
        center: `河南杰文信息技术有限公司，简称“杰文信息”，专注于为高校提供数字化教育创新服务，
               是国内领先的智慧教育数字化解决方案服务商。`,
        dec: `灵犀蓝图专业资源库平台，是集合专业教学资源库、教学互动应用、学生学业评价、岗位能力评价、
              职业倾向评价、人才数据分析、教务信息管理服务于一体的一站式系统平台。`,
      },
      mentalityDesigning: {
        title: "设计思路",
        titleEn: "MENTALITY OF DESIGNING",
        center: `设计定位：能学、辅教、促改；设计原则：需求牵引、应用为王、服务至上；建构逻辑：一体化设计、结构化课程、颗粒化资源、多场景应用；
        建设目标:共建共享优质教学资源，提高专业人才培养培训质量。`,
      },
      platformArchitecture: {
        title: "平台架构",
        titleEn: "PLATFORM ARCHITECTUR",
        center: `灵犀蓝图专业资源库平台，是集合专业教学资源库、教学互动应用、
         学生学业评价、岗位能力评价、职业倾向评价、人才数据分析、教务信息管等服务于一体的一站式系统平台。`,
      },
      specialFunction: {
        title: "特色功能",
        titleEn: "SPECIAL FUNCTION",
        titleList: [
          { 
            id: 0,
            name: "知识图谱" ,
            icon: require("@/assets/tupu_icon.png"),
            iconActive: require("@/assets/tupu_icon_on.png"),
          },
          { 
            id: 1,
            name: "AI助学",
            icon: require("@/assets/aizhuxue_icon.png"),
            iconActive: require("@/assets/aizhuxue_icon_on.png"),
           },
          { 
            id: 2,
            name: "AIGC小灵",
            icon: require("@/assets/aigc_icon.png"),
            iconActive: require("@/assets/aigc_icon_on.png"),
           },
          { 
            id: 3,
            name: "教学资源包",
            icon: require("@/assets/ziyuanbao_icon.png"),
            iconActive: require("@/assets/ziyuanbao_icon_on.png"),
           },
          { 
            id: 4,
            name: "新型数字教材",
            icon: require("@/assets/jiaocai_icon.png"),
            iconActive: require("@/assets/jiaocai_icon_on.png"),
           },
        ],
        centerList: [
          {
            id: 0,
            title: "知识图谱",
            dec: `灵犀蓝图知识图谱，以学科知识为基础，建立各学科知识点层级关系，形成学科知识网络；
               将教学资源进行智能分类、匹配；通过对知识点学习进度和考试结果数据的采集分析,丰富用户画像，实现资源精准推送，
               为学生、企业、社会等不同学习者提供不同学习路径，帮助教师制定针对性教学策略，实现学生个性化成长与发展。
               `,
            imgSrc: require("@/assets/tupu_tu.png"),
          },
          {
            id: 1,
            title: "AI助学",
            dec: `AI助学，利用大模型、大数据、AIGC技术， 改变过去以教师为中心的一对一知识灌输教学模式，
                转向以学习者为中心的人机协作、自适应学习模式；对教学目标和课程要求进行全维度覆盖，结合学生学习进度和行为，
                及时进行诊断预警、质量监控、问题反馈、策略推荐。
               `,
            imgSrc: require("@/assets/aizhuxue_tu.png"),
          },
          {
            id: 2,
            title: "AIGC小灵",
            dec: `灵犀蓝图认知大模型，作为杰文研发的智能学习伙伴，可以帮助用户高效便捷获取信息、知识、灵感；
               基于课程知识进行智能问答，个性化学业辅导；技能答疑解惑还能聊天解忧。
               `,
            imgSrc: require("@/assets/aigc_tu.png"),
          },
          {
            id: 3,
            title: "教学资源包",
            dec: `教学资源包具有模块引用功能，具备多类型资源整合特性。依据教学大纲，精选名师PPT、课堂互动、
               考试题库、课堂设计方案、章节规划、动画素材及教学视频等内容，按单元或课程形式构建为教学资源包，其他教师建课时可直接引用。
               `,
            imgSrc: require("@/assets/ziyuanbao_tu.png"),
          },
          {
            id: 4,
            title: "新型数字教材",
            dec: `灵犀蓝图充分利用互联网、VR/AR技术、虚拟仿真、二维码等技术，将纸质教材与数字化资源、教学资源库、在线课程等资源融合；
               是精准服务教育教学和社会培训的新型教材，具有智能交互、学习行为记录跟踪的数字化图书；可满足线上上架、售卖等需求。
               `,
            imgSrc: require("@/assets/jiaocai_tu.png"),
          },
        ],
      },
      basicFunction: {
        title: "基础功能",
        titleEn: "BASIC FUNCTION",
        centerList: [
          {
            id: 0,
            title: "资源库门户搭建",
            name: "方案概述",
            dec: `<p>支持多门户建设；门户可自行设置管理员；管理员可自主建设和维护门户。</p> 
                <p>可按需设置门户注意颜色风格、模块可拖拽、模块尺寸样式可调整
                内容可编辑等操作，真正实现门户个性化制定。</p>
               `,
            imgSrc: require("@/assets/menhudajian_tu.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/dajian_icon.png"),
                featuresTitle: "快速搭建",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/dajian_icon_er.png"),
                featuresTitle: "自主管理",
              },
            ],
          },
          {
            id: 1,
            title: "资源中心",
            name: "方案概述",
            dec: `<p>内置海量教学资源，支持多格式上传下载，一键调用网络课程下教学资源，为学校资源库建设提供庞大的资源支撑;</p> 
                <p>资源中心资源，可供教师、学生使用，应用于课堂各个教学环节，利用人工智能技术进行资源推荐匹配，提高教学质量。</p>
                <p>同时内置在线浏览、星级评价、学习收藏、转发分享、本地下载等功能，满足教师与学生日常便捷使用。</p>
               `,
            imgSrc: require("@/assets/ziyuan_bg.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/ziyuan_icon.png"),
                featuresTitle: "在线预览",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/ziyuan_icon_er.png"),
                featuresTitle: "学习收藏",
              },
              {
                id: 2,
                featuresIcon: require("@/assets/ziyuan_icon_san.png"),
                featuresTitle: "本地下载",
              },
              {
                id: 3,
                featuresIcon: require("@/assets/ziyuan_icon_si.png"),
                featuresTitle: "星级评价",
              },
              {
                id: 4,
                featuresIcon: require("@/assets/ziyuan_icon_liu.png"),
                featuresTitle: "转发分享",
              },
            ],
          },

          {
            id: 2,
            title: "题库考试中心",
            name: "方案概述",
            dec: `<p>题库资源类型多样，结合智慧考务系统，提供全面、高效、安全的考试管理解决方案，帮助学校实现考试流程的自动化、智能化和信息化;</p> 
                <p>杰文智慧考务系统拥有完整的线上考试流程、丰富的防作弊手段，稳定易用。</p>
               `,
            imgSrc: require("@/assets/tiku_tu.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/tiku_icon.png"),
                featuresTitle: "组建题库",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/tiku_icon_er.png"),
                featuresTitle: "组卷",
              },
              {
                id: 2,
                featuresIcon: require("@/assets/tiku_icon_san.png"),
                featuresTitle: "考试",
              },
              {
                id: 3,
                featuresIcon: require("@/assets/tiku_icon_si.png"),
                featuresTitle: "批阅",
              },
              {
                id: 4,
                featuresIcon: require("@/assets/tiku_wu.png"),
                featuresTitle: "数据统计",
              },
            ],
          },

          {
            id: 3,
            title: "课程中心",
            name: "方案概述",
            dec: `<p>丰富的教学活动、课程资源；智能教学辅助、知识图谱；</p> 
                <p> 创新的课程资源包、数据分析；多场景的应用模式，助力智慧课堂教学模式。</p>
               `,
            imgSrc: require("@/assets/kecehng_tu.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/kecheng_icon.png"),
                featuresTitle: "教学活动",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/kecheng_icon_er.png"),
                featuresTitle: "课程资源包",
              },
              {
                id: 2,
                featuresIcon: require("@/assets/kecheng_icon_san.png"),
                featuresTitle: "课程知识图谱",
              },
              {
                id: 3,
                featuresIcon: require("@/assets/kecheng_icon_si.png"),
                featuresTitle: "笔记课签",
              },
              {
                id: 4,
                featuresIcon: require("@/assets/kecheng_icon_wu.png"),
                featuresTitle: "学业评价",
              },
            ],
          },
          {
            id: 4,
            title: "数据分析",
            name: "方案概述",
            dec: `<p>教学教务数据整合，利用大数据、AI技术，通过大模型多维度数据智能分析（按专业、课程、毕业要求、个人;纵向、横向、环比数据分析）</p> 
                <p> 生成直观、详细的图表，一键生成数据分析报告，辅助科学决策。</p>
               `,
            imgSrc: require("@/assets/shuju_tu.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/shuju_icon.png"),
                featuresTitle: "一键生成数据报告",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/shuju_icon_er.png"),
                featuresTitle: "多维度数据分析",
              },
              {
                id: 2,
                featuresIcon: require("@/assets/shuju_icon_san.png"),
                featuresTitle: "数据大屏展示",
              },
              {
                id: 3,
                featuresIcon: require("@/assets/shuju_icon_si.png"),
                featuresTitle: "AI大模型辅助决策",
              },
            ],
          },
          {
            id: 5,
            title: "虚拟仿真实训中心",
            name: "方案概述",
            dec: `<p>线上虚拟仿真实训平台，打通知识点和资源素材，支持学生线上或线下参与学习实训课程，依据教师的安排完成实训任务，参与教师发起的课堂</p> 
                <p>活动，形成个人学习记录；完成线上课程和学业评价的底层数据互联。</p>
               `,
            imgSrc: require("@/assets/shixun_tu.png"),
            featuresNane: "特色特点",
            features: [
              {
                id: 0,
                featuresIcon: require("@/assets/shixun_icon.png"),
                featuresTitle: "线上虚仿平台",
              },
              {
                id: 1,
                featuresIcon: require("@/assets/shixun_icon_er.png"),
                featuresTitle: "联通知识点",
              },
              {
                id: 2,
                featuresIcon: require("@/assets/shixun_icon_san.png"),
                featuresTitle: "互联课程",
              },
              {
                id: 3,
                featuresIcon: require("@/assets/shixun_icon_si.png"),
                featuresTitle: "资源库素材",
              },
            ],
          },
        ],
        basicFoot: [
          {
            id: 0,
            title: "门户搭建",
            titleEn: "PORTAL CONSTRUCTION",
            icon: require("@/assets/menhu.png"),
          },
          {
            id: 1,
            title: "资源中心",
            titleEn: "RESOURCE CENTER",
            icon: require("@/assets/ziyuan.png"),
          },
          {
            id: 2,
            title: "题库中心",
            titleEn: "QUESTION BANK CENTER",
            icon: require("@/assets/tiku.png"),
          },
          {
            id: 3,
            title: "课程中心",
            titleEn: "CURRICULUM CENTER",
            icon: require("@/assets/kecheng.png"),
          },

          {
            id: 4,
            title: "数据分析",
            titleEn: "ANALYSIS OF DATA",
            icon: require("@/assets/shuju.png"),
          },
          {
            id: 5,
            title: "虚拟实训中心",
            titleEn: "VIRTUAL TRAINING CENTER",
            icon: require("@/assets/shixun.png"),
          },
        ],
      },
      InstitutionalCase: {
        title: "院校案例",
        titleEn: "INSTITUTIONAL CASE",
        navList: [
          {
            id: 0,
            icon: require("@/assets/link/anli_qunabu.png"),
            iconActive: require("@/assets/link/anli_qunabu_bai.png"),
            title: "全部",
          },
          {
            id: 1,
            icon: require("@/assets/link/anli_guojiaji.png"),
            iconActive: require("@/assets/link/anli_guojiaji_bai.png"),
            title: "国家级资源库",
          },
          {
            id: 2,
            icon: require("@/assets/link/anli_shengji.png"),
            iconActive: require("@/assets/link/anli_shengji_bai.png"),
            title: "省级资源库",
          },
        ],
        listResultAll: [
          {
            id: 0,
            list: [
              {
                id: 0,
                imgSrc: require("@/assets/link/anli_tu_yi.png"),
                schoolName: "郑州铁路职业技术学院-铁道机车专业群",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://qun.icve.com.cn/portalproject/9tk7anmyrbcwaexr1n9tg/sta_page/index.html?pgId=9tk7anmyrbcwaexr1n9tg#/",
              },
              {
                id: 1,
                imgSrc: require("@/assets/link/anli_tu_er.png"),
                schoolName: "河南职业技术学院-中国戏曲表演艺术传承与创新资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://www.icve.com.cn/portalproject/themes/default/4ridadsri4pp1nqo3pekw/sta_page/index.html?projectId=4ridadsri4pp1nqo3pekw",
              },
              {
                id: 2,
                imgSrc: require("@/assets/link/anli_tu_san.png"),
                schoolName: "襄阳职业技术学院-特殊教育专业教学资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/qcmxatokky9jkcxseymvvq/sta_page/smallcourse.html?projectId=qcmxatokky9jkcxseymvvq",
              },
              {
                id: 3,
                imgSrc: require("@/assets/link/anli_tu_si.png"),
                schoolName: "日照职业技术学院-水产养殖专业教学资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/whyfadsmfaxb0saqbbpaza/",
              },
              {
                id: 4,
                imgSrc: require("@/assets/link/anli_tu_yi.png"),
                schoolName: "郑州铁路职业技术学院-铁道机车专业群",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://qun.icve.com.cn/portalproject/9tk7anmyrbcwaexr1n9tg/sta_page/index.html?pgId=9tk7anmyrbcwaexr1n9tg#/",
              },
              {
                id: 5,
                imgSrc: require("@/assets/link/anli_tu_liu.png"),
                schoolName: "湖南中医药高等专科学校-针灸推拿传承与创新资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/vlt7afmkfbrcruefn582oq/sta_page/index.html?projectId=vlt7afmkfbrcruefn582oq",
              },
            ],
          },
          {
            id: 1,
            list: [
              {
                id: 6,
                imgSrc: require("@/assets/link/henanzyk.png"),
                schoolName: "河南农业职业学院-种子生产与经营专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/8w58avulsbdorpydrlvika/sta_page/index.html?projectId=8w58avulsbdorpydrlvika",
              },
              {
                id: 7,
                imgSrc: require("@/assets/link/hunanzyk.png"),
                schoolName: "湖南汽车工程职业学院-汽车营销与服务专业教学资源库",
                title: "省级级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "http://hnzyk.36ve.com:8086/index.php/CourseCenter/course/project-course-list?projectId=36",
              },
              {
                id: 8,
                imgSrc: require("@/assets/link/zhengzhoulvyou.png"),
                schoolName: "郑州旅游职业学院-旅游管理资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://www.icve.com.cn/portalproject/themes/default/tduajgsnjfijtqbbs2jq/sta_page/index.html?projectId=tduajgsnjfijtqbbs2jq",
              },
              {
                id: 9,
                imgSrc: require("@/assets/link/jyzyk.png"),
                schoolName: "济源职业技术学院-康复治疗技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/kfzljishu/2671e8e2-56b5-471b-a698-1c5ab5aedbde",
              },
              {
                id: 10,
                imgSrc: require("@/assets/link/nanyang.png"),
                schoolName: "南阳医学高等专科学校-医学影像技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/yxyxjs/15838720606Aa",
              },
              {
                id: 11,
                imgSrc: require("@/assets/link/zhumadian.png"),
                schoolName: "驻马店职业技术学院-数字媒体技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/hi0lazgtukhjqhnfufz43a/sta_page/index.html?projectId=hi0lazgtukhjqhnfufz43a#/home",
              },
            ],
          },
        ],
        listResultOne: [
          {
            id: 0,
            list: [
              {
                id: 0,
                imgSrc: require("@/assets/link/anli_tu_yi.png"),
                schoolName: "郑州铁路职业技术学院-铁道机车专业群",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://qun.icve.com.cn/portalproject/9tk7anmyrbcwaexr1n9tg/sta_page/index.html?pgId=9tk7anmyrbcwaexr1n9tg#/",
              },
              {
                id: 1,
                imgSrc: require("@/assets/link/anli_tu_er.png"),
                schoolName: "河南职业技术学院-中国戏曲表演艺术传承与创新资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://www.icve.com.cn/portalproject/themes/default/4ridadsri4pp1nqo3pekw/sta_page/index.html?projectId=4ridadsri4pp1nqo3pekw",
              },
              {
                id: 2,
                imgSrc: require("@/assets/link/anli_tu_san.png"),
                schoolName: "襄阳职业技术学院-特殊教育专业教学资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/qcmxatokky9jkcxseymvvq/sta_page/smallcourse.html?projectId=qcmxatokky9jkcxseymvvq",
              },
              {
                id: 3,
                imgSrc: require("@/assets/link/anli_tu_si.png"),
                schoolName: "日照职业技术学院-水产养殖专业教学资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/whyfadsmfaxb0saqbbpaza/",
              },
              {
                id: 4,
                imgSrc: require("@/assets/link/anli_tu_yi.png"),
                schoolName: "郑州铁路职业技术学院-铁道机车专业群",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://qun.icve.com.cn/portalproject/9tk7anmyrbcwaexr1n9tg/sta_page/index.html?pgId=9tk7anmyrbcwaexr1n9tg#/",
              },
              {
                id: 5,
                imgSrc: require("@/assets/link/anli_tu_liu.png"),
                schoolName: "湖南中医药高等专科学校-针灸推拿传承与创新资源库",
                title: "国家级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/vlt7afmkfbrcruefn582oq/sta_page/index.html?projectId=vlt7afmkfbrcruefn582oq",
              },
            ],
          },
        ],
        listResultTow: [
          {
            id: 0,
            list: [
              {
                id: 6,
                imgSrc: require("@/assets/link/henanzyk.png"),
                schoolName: "河南农业职业学院-种子生产与经营专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/8w58avulsbdorpydrlvika/sta_page/index.html?projectId=8w58avulsbdorpydrlvika",
              },
              {
                id: 7,
                imgSrc: require("@/assets/link/hunanzyk.png"),
                schoolName: "湖南汽车工程职业学院-汽车营销与服务专业教学资源库",
                title: "省级级专业群资源库",
                type: 0,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "http://hnzyk.36ve.com:8086/index.php/CourseCenter/course/project-course-list?projectId=36",
              },
              {
                id: 8,
                imgSrc: require("@/assets/link/zhengzhoulvyou.png"),
                schoolName: "郑州旅游职业学院-旅游管理资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://www.icve.com.cn/portalproject/themes/default/tduajgsnjfijtqbbs2jq/sta_page/index.html?projectId=tduajgsnjfijtqbbs2jq",
              },
              {
                id: 9,
                imgSrc: require("@/assets/link/jyzyk.png"),
                schoolName: "济源职业技术学院-康复治疗技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/kfzljishu/2671e8e2-56b5-471b-a698-1c5ab5aedbde",
              },
              {
                id: 10,
                imgSrc: require("@/assets/link/nanyang.png"),
                schoolName: "南阳医学高等专科学校-医学影像技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/yxyxjs/15838720606Aa",
              },
              {
                id: 11,
                imgSrc: require("@/assets/link/zhumadian.png"),
                schoolName: "驻马店职业技术学院-数字媒体技术专业教学资源库",
                title: "省级专业群资源库",

                type: 1,
                rightIcon: require("@/assets/link/anli_jiantou.png"),
                link: "https://zyk.icve.com.cn/portalproject/themes/default/hi0lazgtukhjqhnfufz43a/sta_page/index.html?projectId=hi0lazgtukhjqhnfufz43a#/home",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {},

  methods: {

    //tab点击事件
    handleCLickTab(index){
      this.tabActive = index
    },


    //轮播图的监听事件
    handleChangCarouse(index) {
      this.lightHeight = index;
      this.$refs.cardShow.setActiveItem(index);
      this.$refs.cardShowMedia.setActiveItem(index);
    },
    handleCLick(index) {
      console.log("点击了", index);
      this.$refs.cardShow.setActiveItem(index);
      this.$refs.cardShowMedia.setActiveItem(index);
      this.lightHeight = index;
    },
    //基础功能轮播图的监听事件
    handleChangBasicCarouse(index) {
      this.basicActive = index;
    },

    //特色功能鼠标移入事件
    handleMouseenterSpecial(index) {
      this.lightHeight = index;
      this.$refs.cardShow.setActiveItem(index);
      this.$refs.cardShowMedia.setActiveItem(index);
    },
    //特色功能鼠标移出事件
    handleMouseLeaveSpecial(index) {
      this.lightHeight = index;
      this.$refs.cardShow.setActiveItem(index);
      this.$refs.cardShowMedia.setActiveItem(index);
    },
    //基础功能鼠标移入事件
    handleMouseenter(index) {
      this.basicActive = index;
      this.$refs.basicRef.setActiveItem(index);
      this.$refs.basicRefMedia.setActiveItem(index);
    },
    //基础功能鼠标移出事件
    handleMouseLeave(index) {
      this.basicActive = index;
      this.$refs.basicRef.setActiveItem(index);
      this.$refs.basicRefMedia.setActiveItem(index);
    },

    //院校案例的 tab 点击事件
    handleClickNav(index) {
      // console.log('点击了111',index)
      this.caseNavActive = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  .home_top {
    position: relative;
    .home_banner {
      height: 686px;
      background: url("~@/assets/banner.png") no-repeat center center;
      background-size: cover;
      .imgLogo {
        display: inline-block;
        margin-top: 50px;
        margin-left: 360px;
      }
    }
    .home_jianjie {
      height: 657px;
      background: url("~@/assets/bg_jianjie.png") no-repeat center center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .jianjie_left {
        flex: 5;
      }
      .jianjie_center {
        flex: 5;
        .p_title {
          font-size: 36px;
          color: #333;
          font-weight: 600;
        }
        .p_titleEn {
          font-size: 14px;
          color: #999;
          margin-top: 16px;
        }
        .p_name {
          color: #666;
          font-size: 16px;
          margin-top: 38px;
          font-weight: 550;
        }
        .p_center {
          width: 600px;
          font-size: 14px;
          color: #999;
          line-height: 2;
          margin-top: 38px;
        }
        .p_dec {
          width: 600px;
          font-size: 14px;
          color: #4191ff;
          line-height: 2;
          margin-top: 35px;
        }
      }
    }
    .home_tab {
      width: 1152px;
      height: 160px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 10px 38px 2px rgba(30, 47, 96, 0.06);
      position: absolute;
      top: 600px;
      left: 50%;
      transform: translateX(-50%);
      .home_tab_item {
        width: 288px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-left: solid 1px #f1f1f1;
        cursor: pointer;
        .name {
          margin: 15px 0;
          font-size: 16px;
          font-weight: 600;
        }
        .title {
          width: 150px;
          text-align: center;
          line-height: 2;
          font-size: 14px;
          color: #999;
        }
      }
      .tabActive{
        height: 160px;
        background-color: #2F4270;
        .name, .title{
            color: #FFF;
        }
      }
      .home_tab_item:first-child {
        border-left: none;
      }
    }
  }

  .mentalityDesigning {
    height: 830px;
    background: url("~@/assets/bg_shejisilu.png") no-repeat center center;
    background-size: cover;
  }
  .platformArchitecture {
    height: 898px;
    background: url("~@/assets/bg_pingtaijiaguo.png") no-repeat center center;
    background-size: cover;
  }
  .InstitutionalCase {
    height: 1254px;
    background: url("~@/assets/link/bg_anli.png") no-repeat center center;
    background-size: cover;
    .case_center {
      position: relative;
      .case_warp {
        height: 80px;
        margin-top: 40px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .case_tap {
          flex: 1;
          display: flex;
          align-items: center;
          .case_tab_item {
            width: 180px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .image {
              width: 18px;
              height: 18px;
            }
            .nav_title {
              font-size: 18px;
              color: #333;
              font-weight: 600;
              margin-left: 13px;
            }
          }
          .caseNavActive {
            background: linear-gradient(128deg, #7fa8f8, #3e74fe) !important;
            .nav_title {
              color: #fff !important;
            }
          }
        }
        .more {
          width: 100px;
          text-align: center;
          line-height: 80px;
        }
      }
      .CaseCarouselMedia {
        display: none;
      }
      .CaseCarousel {
        display: block;
      }
    }
  }
  .basicFunction {
    height: 943px;
    background: url("~@/assets/bg_jichugongneng.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .basic_heard {
      padding-top: 70px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .basic_title {
        font-size: 36px;
        color: #fff;
        font-weight: 600;
        word-spacing: 10px;
      }
      .basic_titleEn {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        padding-top: 13px;
      }
    }
    .basicn_center {
      margin-top: 112px;
      .basicn_carousel {
        display: block;
      }
      .basicn_carousel_media {
        display: none;
      }
      .basicn_mod {
        // width: 100%;
        // height: 550px;
        // margin-top: 108px;
        display: flex;
        justify-content: space-between;
        align-self: start;
        .basicn_left {
          width: 350px;
        }
        .basicn_right {
          width: 1010px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .basicn_right_top {
            .right_title {
              font-size: 26px;
              margin-top: 26px;
              color: #fff;
            }
            .right_name {
              font-size: 18px;
              margin-top: 65px;
              margin-bottom: 23px;
              color: #fff;
            }
            .right_dec {
              color: rgba(255, 255, 255, 0.6);
              line-height: 1.5;
            }
          }
          .basicn_right_bottom {
            .right_bottom_divider {
              width: 960px;
              height: 1px;
              background-color: rgba(255, 255, 255, 0.1);
            }
            .featuresNane {
              margin-top: 14px;
              color: #fff;
              font-size: 20px;
            }
            .features_warp {
              margin-top: 27px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 73px;
              .featuresItem {
                width: 170px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0 16px;
                .featuresTitle {
                  color: #fff;
                  font-size: 16px;
                  margin-left: 20px;
                }
              }
              .featuresItemFour {
                width: 200px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0 25px;
                .featuresTitle {
                  color: #fff;
                  font-size: 16px;
                  margin-left: 20px;
                }
              }
              .featuresItem:first-child {
                margin-left: 0;
              }
              .featuresItemFour:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .basic_foot {
      width: 100%;
      height: 150px;
      background-color: #18084d;
      .basicFootWarp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .basicFootItem {
          width: 208px;
          height: 150px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 28px;
          border-left: solid 1px rgba(255, 255, 255, 0.15);
          cursor: pointer;
          .foot_title {
            color: #fff;
            font-size: 16px;
          }
          .foot_titleEn {
            color: rgba(255, 255, 255, 0.2);
            font-size: 10px;
            margin-top: 10px;
          }
          .foot_divider {
            width: 10px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
          }
          .image {
            width: 20px;
            height: 20px;
          }
        }
        .basicActive {
          width: 365px;
          background: linear-gradient(145deg, #8411ff, #2552cc);
          .foot_title {
            font-size: 20px !important;
          }
          .foot_dietal {
            width: 116px;
            color: #fff;
            font-size: 14px;
            padding: 10px;
            border: solid 1px rgba(255, 255, 255, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 28px;
            cursor: pointer;
          }
        }
      }
      .basicFootItem:last-child {
        border-right: solid 1px rgba(255, 255, 255, 0.15);
      }
    }
  }
  .specialFunction {
    height: 780px;
    background: url("~@/assets/bg_tesegongneng.png") no-repeat center center;
    background-size: cover;
    .specialFunction_center {
      margin-top: 84px;
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .specialFunction_center_left{
          width: 276px;
          height: 530px;
          background-color: #FFF;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .titleList{
            margin-top: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 245px;
            height: 80px;
            cursor: pointer;
            .image,
            .name{
              padding-left: 36px;
            }

          }
          .curLightHeight {
            background: #EDF2FF;
            border-radius: 0px 41px 41px 0px;
            .image{
              width: 45px;
              height: 45px;
            }
          }
        
      }
      .specialFunction_center_right{
        width: 1106px;
        height: 530px;
        background-color: #FFF;
        .carouse_warp {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 80px 42px 42px 42px;
          .image{
            width: 703px;
            height: 407px;
          }
          .carouse_dec{
            width: 285px;
            font-size: 16px;
            color: #5c697b;
            line-height: 2;
            margin-left: 30px;
          }

          .carouselItem_left_media {
            display: none;
          }
        }
      }
    
   
   }
   .specialFunction_center_media{
    display: none;
   }
  }
  .com_heard {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .com_title {
      font-size: 36px;
      color: #333;
      font-weight: 600;
      word-spacing: 10px;
    }
    .com_titleEn {
      font-size: 12px;
      color: #6f6f6f;
      font-weight: 600;
      padding-top: 13px;
    }
  }
  .com_center {
    width: 1152px;
    height: 46px;
    margin: 0 auto;
    text-align: center;
    line-height: 2;
    margin-top: 43px;
    color: #666;
    font-size: 16px;
  }

  .paperFooter {
    height: 80px;
    background-color: #282e31;
    display: flex;
    justify-content: center;
    align-items: center;
    .comp_info {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        color: #4f5a5f;
        margin: 0 5px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .home {
    width: 100%;
    overflow: hidden;
    .home_top {
      .home_banner {
        height: 230px;
        .imgLogo {
          width: 200px;
          height: 25px;
          display: inline-block;
          margin-top: 16px;
          margin-left: 10px;
        }
      }
      .home_jianjie {
        height: 340px;
        .jianjie_left {
          flex: 5;
        }
        .jianjie_center {
          flex: 5;
          margin-top: 20px;
          .p_title {
            font-size: 16px;
            font-weight: 600;
          }
          .p_titleEn {
            font-size: 10px;
            margin-top: 10px;
          }
          .p_name {
            font-size: 10px;
            margin-top: 16px;
          }
          .p_center {
            width: 200px;
            font-size: 10px;
            line-height: 1.5;
            margin-top: 10px;
          }
          .p_dec {
            width: 200px;
            font-size: 10px;
            line-height: 1.5;
            margin-top: 15px;
          }
        }
      }
      .home_tab {
        width: 450px;
        height: 100px;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
        .home_tab_item {
          width: 25%;
          .image {
            width: 25px;
            height: 25px;
          }
          .name {
            font-size: 12px;
            margin: 8px 0;
          }
          .title {
            width: 100px;
            line-height: 1.5;
            font-size: 10px;
          }
        }
        .tabActive{
          height: 100px;
          background-color: #2F4270;
          .name, .title{
              color: #FFF;
          }
        }
      }
    }
    .mentalityDesigning {
      height: 360px;
    }
    .platformArchitecture {
      height: 380px;
    }
    .specialFunction {
      height: 480px;
      .specialFunction_center_media {
        display: none;
      }
      .specialFunction_center {
        margin-top: 34px;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
      .specialFunction_center_left{
        width: 150px;
        height: 330px;
        background-color: #FFF;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .titleList{
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 165px;
          height: 50px;
          cursor: pointer;
          .image,
          .name{
            padding-left: 12px;
            font-size: 14px;
          }

          }
          .curLightHeight {
            background: #EDF2FF;
            border-radius: 0px 31px 31px 0px;
            .image{
              width: 25px;
              height: 25px;
            }
          }
        
      }
      .specialFunction_center_right{
        width: 580px;
        height: 330px;
        background-color: #FFF;
        .carouse_warp {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 30px 22px 22px 22px;
          .image{
            width: 380px;
            height: 240px;
          }
          .carouse_dec{
            width: 200px;
            font-size: 14px;
            color: #5c697b;
            line-height: 16px;
            margin-left: 10px;
          }

          .carouselItem_left_media {
            display: none;
          }
        }
      }
    
   
   }
    }
    .basicFunction {
      height: 845px;
      .basic_heard {
        padding-top: 25px;
        .basic_title {
          font-size: 16px;
        }
        .basic_titleEn {
          font-size: 8px;
        }
      }
      .basicn_center {
        margin-top: 40px;
        .basicn_carousel {
          display: none;
        }
        .basicn_carousel_media {
          display: block;
        }
        .basicn_mod {
          .basicn_left {
            width: 280px;
          }
          .basicn_right {
            width: 300px;
            .basicn_right_top {
              .right_title {
                font-size: 18px;
                margin-top: 10px;
              }
              .right_name {
                font-size: 16px;
                margin-top: 30px;
              }
              .right_dec {
                font-size: 14px;
              }
            }
            .basicn_right_bottom {
              .right_bottom_divider {
                width: 280px;
                margin: 10px 0;
              }
              .featuresNane {
                font-size: 14px;
              }
              .features_warp {
                width: 100%;
                flex-wrap: wrap;
                margin-top: 10px;
                .featuresItem,
                .featuresItemFour {
                  width: 48%;
                  margin: 5px 0;
                  .image {
                    width: 26px;
                    height: 26px;
                  }
                  .featuresTitle {
                    font-size: 12px;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .basic_foot {
        height: 305px;
        .basicFootWarp {
          width: 100%;
          flex-wrap: wrap;
          .basicFootItem {
            width: 30%;
            padding-left: 16px;
            border-bottom: solid 1px rgba(255, 255, 255, 0.15);
          }
          .basicActive {
            width: 30%;
          }
        }
      }
    }
    .InstitutionalCase {
      height: 860px;
      .case_center {
        .case_warp {
          .case_tap {
            .case_tab_item {
              width: 150px;
              .nav_title {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .com_heard {
      padding-top: 25px;
      .com_title {
        font-size: 16px;
      }
      .com_titleEn {
        font-size: 8px;
      }
    }
    .com_center {
      width: 450px;
      font-size: 8px;
      margin-top: 8px;
    }
  }
}
@media only screen and (min-width: 667px) and (min-width: 1024px) and (max-width: 1336px) {
  .home {
    width: 100%;
    overflow: hidden;
    .home_top {
      .home_banner {
        height: 480px;
        .imgLogo {
          display: inline-block;
          margin-top: 30px;
          margin-left: 70px;
        }
      }
      .home_jianjie {
        height: 480px;
        .jianjie_center {
          margin-top: 60px;
          .p_title {
            font-size: 26px;
          }
          .p_titleEn {
            font-size: 10px;
          }
          .p_center,
          .p_dec {
            width: 400px;
          }
        }
      }
      .home_tab {
        width: 850px;
        height: 120px;
        position: absolute;
        top: 420px;
        left: 50%;
        transform: translateX(-50%);
        .home_tab_item {
          width: 25%;
          .image {
            width: 25px;
            height: 25px;
          }
          .name {
            margin: 8px 0;
          }
          .title {
            line-height: 1.5;
            font-size: 12px;
          }
        }
        .tabActive{
          height: 120px;
          background-color: #2F4270;
          .name, .title{
              color: #FFF;
          }
        }
      }
    }
    .mentalityDesigning {
      height: 560px;
    }
    .platformArchitecture {
      height: 650px;
    }
    .specialFunction {
      height: 610px;
      .specialFunction_center {
        display: none;
      }
      .specialFunction_center_media {
        display: block;
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .specialFunction_center_left{
          width: 210px;
          height: 430px;
          background-color: #FFF;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .titleList{
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 200px;
            height: 60px;
            cursor: pointer;
            .image,
            .name{
              padding-left: 26px;
            }

          }
          .curLightHeight {
            background: #EDF2FF;
            border-radius: 0px 41px 41px 0px;
            .image{
              width: 45px;
              height: 45px;
            }
          }
        
      }
      .specialFunction_center_right{
        width: 720px;
        height: 430px;
        background-color: #FFF;
        .carouse_warp {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 60px 22px 22px 22px;
          .image{
            width: 500px;
            height: 300px;
          }
          .carouse_dec{
            width: 180px;
            font-size: 14px;
            color: #5c697b;
            line-height: 25px;
            margin-left: 20px;
          }

          .carouselItem_left_media {
            display: none;
          }
        }
      }
      }
    }
    .basicFunction {
      height: 740px;
      background-size: cover;
      .basic_heard {
        padding-top: 50px;
        .basic_title {
          font-size: 26px;
        }
        .basic_titleEn {
          font-size: 10px;
        }
      }
      .basicn_center {
        margin-top: 80px;
        .basicn_carousel {
          display: none;
        }
        .basicn_carousel_media {
          display: block;
        }
        .basicn_mod {
          .basicn_left {
            width: 280px;
            .image {
              width: 280px;
            }
          }
          .basicn_right {
            width: 620px;
            .basicn_right_bottom {
              .features_warp {
                margin-bottom: 43px;
              }
            }
          }
        }
      }
      .basic_foot {
        height: 120px;
        .basicFootWarp {
          .basicFootItem {
            width: 125px;
            padding-left: 16px;
            height: 120px;
            .foot_title {
              font-size: 14px;
            }
            .foot_titleEn {
              font-size: 8px;
            }
            .foot_divider {
              margin: 14px 0;
            }
          }

          .basicActive {
            width: 280px;
            .foot_title {
              font-size: 18px !important;
            }
            .foot_dietal {
              width: 80px;
              font-size: 12px;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .InstitutionalCase {
      height: 950px;
      background-size: cover;
      .case_center {
        .CaseCarouselMedia {
          display: block;
        }
        .CaseCarousel {
          display: none;
        }
      }
    }

    .com_heard {
      padding-top: 40px;
      .com_title {
        font-size: 26px;
      }
      .com_titleEn {
        font-size: 10px;
      }
    }
    .com_center {
      width: 850px;
      margin-top: 23px;
    }
  }
}
</style>
